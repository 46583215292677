import React from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import _ from 'lodash'
import {fetchDevRoll} from '../actions/api'
import Loading from '../components/Loading'
class DevRoll extends React.Component {
	componentDidMount() {
		this.props.fetchDevRoll();
	}
	renderRoll = () => {
		
	};
	render(){
		console.log(this.props.devRoll);
		if (!this.props.devRoll) {
			return <Loading />;
		}
		if(this.props.devRoll) {
			 return this.props.devRoll.map(item => {
				return(
					<div className="devRollItem">
					<Link to={`/dev/${item.slug}`} className="miniBlogLink">
						<div className="miniblogImg marginBottom1em">
							<img src={item.acf.devThumbnail} alt="" />
						</div>
						<div>
							<h6>{item.title.rendered}</h6>
						</div>
					</Link>
					</div>
				);
			});
		}

		
	}
}
const mapStateToProps = (state) => {
	return {
		devRoll: state.api.devRoll,
	};
};
export default connect(mapStateToProps,{fetchDevRoll})(DevRoll);