import React from 'react'
import {connect} from 'react-redux'
import thumb from '../img/kylelogo.png'
import {Link} from 'react-router-dom'
import {fetchBlogRoll} from '../actions/api'
import Loading from '../components/Loading'

class MiniBlogRoll extends React.Component {
	componentDidMount() {
		this.props.fetchBlogRoll();
	}
	renderRoll = () => {
		return this.props.blogRoll.map(blog => {
			<h1>blog.title.rendered</h1>
		});	

	};
	render() {
		if (!this.props.blogRoll) {
			return <div><Loading /></div>;
		}
		return this.props.blogRoll.map(blog => {
			let img = blog.acf.blog_thumbnail;
			return(
				<div key={blog.id} className="miniBlogItem gridCenter contentPadding">
					<Link to={`/blog/${blog.slug}`} className="miniBlogLink">
						<div className="miniblogImg marginBottom1em">
							<img src={img ? img : thumb} alt="" />
						</div>
						<h6 className="">{blog.title.rendered}</h6>
						<time className="miniBlogDate">{new Date(blog.date).toDateString('dddd, MMMM ,YYYY')}</time>
					</Link>
				</div>
			);
		});
	}
}

const mapStateToProps = (state) => {
	return {
		blogRoll: state.api.blogRoll,
	};
};
export default connect(mapStateToProps, {fetchBlogRoll})(MiniBlogRoll);