import React from 'react'
import ReactDOM from 'react-dom'
import ReactSwipe from 'react-swipe'
import leftArrow from '../svg/leftArrow.svg'
import rightArrow from '../svg/rightArrow.svg'

const Carousel = (props) => {
  let reactSwipeEl;
 	const loopPics = () => {
    if(!props.roll) {
      return <div>loading</div>
    }
 		 return props.roll.map((photo, index) => {

        	return(
        		<div key="index" className="slideImgContainer flexCenter" key={photo.length}><img src={photo.gallery_item.url} alt="" /></div>
    		);
      })
 	};
  return (
    < >
      	<ReactSwipe
        className="carousel"
        swipeOptions={{ continuous: false }}
        ref={el => (reactSwipeEl = el)}
      >
       		{loopPics()}
      </ReactSwipe>
      <div className="leftArrow clickIt" onClick={() => reactSwipeEl.prev()}><img src={leftArrow} alt="Left Arrow Button" /></div>
      <div className="rightArrow clickIt" onClick={() => reactSwipeEl.next()}><img src={rightArrow} alt="Right Arrow Button" /></div>
    </>
  );
}
export default Carousel;