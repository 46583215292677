import React from 'react'
import {connect} from 'react-redux'

import Header from './Header'
import BarChartItem from '../components/BarChartItem'
import Footer from './Footer'
import MiniBlogRoll from '../components/MiniBlogRoll'
import DevRoll from '../components/DevRoll'
import {fetchDevCategory} from '../actions/api'
import kyle from '../img/kyle.jpg'
import headless from '../svg/Headless.svg'
import intuitive from '../svg/Intuitive.svg'
import unique from '../svg/Unique.svg'
import dynamic from '../svg/Dynamic.svg'
import ContactForm from '../components/ContactForm'

class DevHome extends React.Component {
	componentDidMount() {
	}
	componentWillUnmount() {
	}
	handleCategoryToggle = (catID) => {
		this.props.fetchDevCategory(catID);
	};
	render() {
		
		return(
			<div >
				<div className="maxWidthWrap overflow">
				<div>
					<div className="gridCenter marginTop5em">
						<h1>Hello, <span className="primaryColorText">I'm Kyle Valenzuela.</span></h1>
						<h1>I am a full-stack Developer</h1>
					</div>
					<div className="gridTwoFour contentPadding">
						<div className="gridCenter">
							<div className=""><img src={headless} alt="" / ></div>
							<h4 className="marginTop">Headless</h4>
							<p className="gridCenter contentPadding">Detatch your front-end codebase from your back-end.</p>
						</div>
						<div className="gridCenter">
							<div className=""><img src={unique} alt="" / ></div>
							<h4 className="marginTop">Unique</h4>
							<p className="gridCenter contentPadding">Engineered to your needs!</p>
						</div>
						<div className="gridCenter">
							<div className=""><img src={intuitive} alt="" / ></div>
							<h4 className="marginTop">Intuitive</h4>
							<p className="gridCenter contentPadding">Top of the line User / Customer Experience. </p>
						</div>
						<div className="gridCenter">
							<div className=""><img src={dynamic} alt="" / ></div>
							<h4 className="marginTop">Dynamic</h4>
							<p className="gridCenter contentPadding">Designed to look beautiful on all devices.</p>
						</div>
					</div>
					<div className="gridOneTwo contentPadding">
						<div>
							<div className="gridCenter marginBottom1em"><img className="hexImg" src={kyle} alt=""/></div>
							<h4 className="gridCenter marginTop1em">About</h4>
							<p>I'm a front-end developer, UI Engineer, and Overall Product Guy with a passion for UI animations and creating a lightning-fast user experiences.</p>
						</div>
						<div className="gridCenter contentPadding">
							<div className="chartWrapper">
								<BarChartItem 
									label="CSS"
									css="zeroPercent ninetyPercent"
									percentage="90%"
								/>
								<BarChartItem 
									label="HTML"
									css="ninetyPercent"
									percentage="90%"
								/>
								<BarChartItem 
									label="React"
									css="eightyPercent"
									percentage="80%"
								/>
								<BarChartItem 
									label="Javascript"
									css="eightyPercent"
									percentage="80%"
								/>
								<BarChartItem 
									label="Django"
									css="sixtyFivePercent"
									percentage="65%"
								/>
								<BarChartItem 
									label="Python"
									css="sixtyFivePercent"
									percentage="65%"
								/>
								<BarChartItem 
									label="WordPress"
									css="ninetyPercent"
									percentage="90%"
								/>
								<BarChartItem 
									label="PHP"
									css="seventyPercent"
									percentage="70%"
								/>
								<BarChartItem 
									label="UI / UX"
									css="ninetyPercent"
									percentage="90%"
								/>
								<BarChartItem 
									label="Photoshop"
									css="ninetyPercent"
									percentage="90%"
								/>
								<BarChartItem 
									label="Sketch"
									css="sixtyFivePercent"
									percentage="65%"
								/>
							</div>
						</div>
					</div>

					<div className="portfolioWrapper contentPadding">
						<h1 className="gridCenter marginBottom1em">Projects</h1>
						<div className="gridGap10px photoFilterContainer categoryWrapper gridCenter marginBottom2em">
							<div>
								<div onClick={() => this.handleCategoryToggle('20+21+22')}  className="inlineBlock clickIt photoFilterItem pLink">All</div>
								<div onClick={() => this.handleCategoryToggle(20)}  className="inlineBlock clickIt photoFilterItem pLink">React / Javascript</div>
								<div onClick={() => this.handleCategoryToggle(21)}  className="inlineBlock clickIt photoFilterItem pLink">PHP / WordPress</div>
								<div onClick={() => this.handleCategoryToggle(22)}  className="inlineBlock clickIt photoFilterItem pLink">Python / Django</div>
							</div>
						</div>
						<div className="devRollWrap gridTwoFour positionRelative">
							<DevRoll />
						</div>

					</div>
					<div className="miniBlogContainer">
						<h1 className="gridCenter marginBottom1em">Blog</h1>
						<div className="gridOneTwoFour positionRelative"><MiniBlogRoll /></div>
					</div>
				</div>
			</div>
			<div className="gridCenter fullWidth cfWrap"><ContactForm /></div>
			</div>
		);
	}
}
export default connect(null, {fetchDevCategory})(DevHome);