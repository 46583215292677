import React from 'react'
import {connect} from 'react-redux'
import {fetchContactForm} from '../actions/api'
import Footer from '../containers/Footer'
class ContactForm extends React.Component {
	
	render(){
		
		return(
			<form className="contactForm" action="https://api.web3forms.com/submit" method="POST">
				<h2 className="pText">Have Questions? Let's talk!</h2>
			    <input type="hidden" name="apikey" value="48a53dc7-b265-4a88-841c-9d829603be92"/>
			    <input type="hidden" name="subject" value="New Submission from Web3Forms"/>
			    <input type="hidden" name="redirect" value="https://web3forms.com/success"/>
			    <input type="checkbox" name="botcheck" id="" style={{display: 'none'}}/>
			    <input type="hidden" name="recaptcha_response" id="recaptchaResponse"/>
			  
			    <input className="inputItem marginBottom1em" placeholder="Email" type="email" name="email" required/>
			    <input className="inputItem marginBottom1em" placeholder="Name" type="text" name="Name" required/>
			    <input className="inputItem marginBottom1em" placeholder="Phone Number" type="text" name="Phone Number" required/>
			    <textarea  className=" inputArea marginBottom1em" placeholder="I need help building a website!" name="message" cols="30" rows="10" required></textarea>

			    <button className="inputButton clickIt" type="submit">Submit Form</button>
				<Footer />

			</form>
		);
	}
}

export default connect(null, {})(ContactForm);