import {
	FETCH_BLOG_ROLL,
	FETCH_DEV_ROLL,
	FETCH_BLOG_POST,
	CLEAR_DEV_ROLL,
	FETCH_PHOTO_ROLL,
	FETCH_ART_ROLL,
	FETCH_DEV_POST,
	FETCH_CLICKED_DRAW,
	OVERLAY_IS_CLICKED,
	FETCH_CONTACT_FORM
} from './types';
import _ from 'lodash';
import axios from 'axios'

export const fetchBlogRoll = () => async (dispatch) => {
	axios.get('https://core.kylevalenzuela.com//wp-json/wp/v2/posts/?per_page=10')
	.then(function (request) {
		dispatch({type: FETCH_BLOG_ROLL, payload: request.request.response});
	})
	.catch(function (error) {
		console.log(error);
	}
)	.then(function () {
		// always executed
	}); 


};

export const fetchDevRoll = () => async (dispatch) => {
	axios.get('https://core.kylevalenzuela.com//wp-json/wp/v2/dev')
	.then(function (request) {
		console.log(request.request.response)
		dispatch({type: FETCH_DEV_ROLL, payload: request.request.response});
	})
	.catch(function (error) {
		console.log(error);
	})
	.then(function () {
		// always executed
	}); 

};

export const clearDevRoll = () => async (dispatch) => {
	dispatch({type: CLEAR_DEV_ROLL});
};



export const fetchPhotoRoll = () => async (dispatch) => {
	axios.get('https://core.kylevalenzuela.com//wp-json/wp/v2/photography')
	.then(function (request) {
		dispatch({type: FETCH_PHOTO_ROLL, payload: request.request.response});
	})
	.catch(function (error) {
		console.log(error);
	})
	.then(function () {
		// always executed
	}); 

};


export const fetchDrawRoll = () => async (dispatch) => {
	axios.get('https://core.kylevalenzuela.com//wp-json/wp/v2/art')
	.then(function (request) {
		dispatch({type: FETCH_ART_ROLL, payload: request.request.response});
	})
	.catch(function (error) {
		console.log(error);
	})
	.then(function () {
		// always executed
	}); 
};

export const fetchDrawCategory = (catID) => async (dispatch) => {
	axios.get('https://core.kylevalenzuela.com//wp-json/wp/v2/art?categories=' + catID )
	.then(function (request) {
		dispatch({type: FETCH_ART_ROLL, payload: request.request.response});
	})
	.catch(function (error) {
		console.log(error);
	})
	.then(function () {
		// always executed
	}); 
};

export const fetchPhotoCategory = (catID) => async (dispatch) => {
	axios.get('https://core.kylevalenzuela.com//wp-json/wp/v2/photography/?photo_genre=' + catID )
	.then(function (request) {
		dispatch({type: FETCH_PHOTO_ROLL, payload: request.request.response});
	})
	.catch(function (error) {
		console.log(error);
	})
	.then(function () {
		// always executed
	}); 
};

export const fetchDevCategory = (catID) => async (dispatch) => {
	axios.get('https://core.kylevalenzuela.com//wp-json/wp/v2/dev?categories=' + catID )
	.then(function (request) {
		dispatch({type: FETCH_DEV_ROLL, payload: request.request.response});
	})
	.catch(function (error) {
		console.log(error);
	})
	.then(function () {
		// always executed
	}); 
};


export const fetchBlogPost = (slug) => async (dispatch) => {
	axios.get('https://core.kylevalenzuela.com//wp-json/wp/v2/posts/?slug=' + slug)
	.then(function (request) {
		dispatch({type: FETCH_BLOG_POST, payload: request.request.response});
	})
	.catch(function (error) {
		console.log(error);
	})
	.then(function () {
		// always executed
	}); 
};

export const fetchDevPost = (slug) => async (dispatch) => {
	axios.get('https://core.kylevalenzuela.com//wp-json/wp/v2/dev?slug=' + slug)
	.then(function (request) {
		dispatch({type: FETCH_DEV_POST, payload: request.request.response});
	})
	.catch(function (error) {
		console.log(error);
	})
	.then(function () {
		// always executed
	}); 
};

export const fetchClickedDraw = (index) => async (dispatch) => {
	dispatch({type: OVERLAY_IS_CLICKED, payload: true });
	setTimeout(() => {
		dispatch({type: FETCH_CLICKED_DRAW, payload: index });
	}, 500);
};


export const modalClose = () => async (dispatch) => {
	dispatch({type: OVERLAY_IS_CLICKED, payload: false });
};

export const fetchContactForm = () => async (dispatch) => {
	axios.get('https://core.kylevalenzuela.com//wp-json/wp/v2/pages/593')
	.then(function (request) {
		dispatch({type: FETCH_CONTACT_FORM, payload: request.request.response});
	})
	.catch(function (error) {
		console.log(error);
	})
	.then(function () {
		// always executed
	}); 
};