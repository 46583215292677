
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
//import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import rootReducer from '../reducers'; // the value from combineReducers
import StateLoader from './stateloader';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; 

const loader = new StateLoader();

const persistConfig = {
 key: 'root',
 storage: storage,
 stateReconciler: hardSet // see "Merge Process" section for details.
};

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer, loader.loadState(), composeEnhancers(applyMiddleware(thunk)));
store.subscribe(() => {
	loader.saveState(store.getState());
});
export const persistor = persistStore(store);