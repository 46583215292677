//API Roll 
export const FETCH_BLOG_ROLL = 'FETCH_BLOG_ROLL'
export const FETCH_DEV_ROLL = 'FETCH_DEV_ROLL'
export const FETCH_BLOG_POST = 'FETCH_BLOG_POST'
export const FETCH_DEV_POST = 'FETCH_DEV_POST'
export const CLEAR_DEV_ROLL = 'CLEAR_DEV_ROLL'
export const FETCH_PHOTO_ROLL = 'FETCH_PHOTO_ROLL'
export const FETCH_ART_ROLL = 'FETCH_ART_ROLL'
export const FETCH_CLICKED_DRAW = 'FETCH_CLICKED_DRAW'
export const OVERLAY_IS_CLICKED = 'OVERLAY_IS_CLICKED'
export const FETCH_CONTACT_FORM = 'FETCH_CONTACT_FORM'
