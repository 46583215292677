import react from 'react';

const BarChartItem = (props) => {
	return(
		<div className="chartItem marginBottomHalf">
			<div className="chartGrid">
				<div className="gridCenter primaryColorText chartLabel">{props.label}</div>
				<div className={`chartBar ${props.css}`}></div>
			</div>
			<div className="percentage verticalCenterFlex">{props.percentage}</div>
		</div>
	);
};

export default BarChartItem;