import React from 'react'
import {Link} from 'react-router-dom'
import Logo from '../svg/logo.svg'
import Particles from 'react-particles-js'

class Header extends React.Component {

	componentDidMount(){
		const head = document.getElementById('headerHover');
		const body = document.getElementById('bodyHover');
		head.onmouseover = () => {
			body.classList.add('hoverOffset');
		};
		head.onmouseout = () => {
			body.classList.remove('hoverOffset');
		};
	}
	render(){ 
		
		
		return(
			<div id="headerHover" className="headerContainer ">
				<Particles 
					className="particlesContainer"
					params={{
					    "particles": {
					        "number": {
					            "value": 30
					        },
					        "size": {
					            "value": 3,
					            "anim": {
							        "enable": false,
							        "speed": 80,
							        "size_min": 0.1,
							        "sync": false
							      }
					        },
					        "move": {
						      "enable": true,
						      "speed": 1,
						      "direction": "none",
						      "random": false,
						      "straight": false,
						      "out_mode": "out",
						      "bounce": false,
						  	},
						  	"color": {
						      "value": "hsl(289, 27%, 75%)"
						    },
						    "opacity": {
						      "value": 0.1,
						      "random": false,
						      "anim": {
						        "enable": false,
						        "speed": 1,
						        "opacity_min": 0.1,
						        "sync": false
						      }
						    },
					    },
					    "interactivity": {
					        "events": {
					            "onhover": {
					                "enable": false,
					                "mode": "repulse"
					            }
					        }
					    }
					}}
				/>
				<div className="marginTop3em contentPaddinghalf navGrid">
					
						<div className="headerLogo ">
							<div class="box_wrap">
							<div class="box_rorate bg1"></div>
							<div class="box_rorate bg2"></div>
							<div class="box_txt">
								<div>
									<Link to="/"><img src={Logo} / ></Link>
								</div>
							</div>
						</div>
					</div>
					
					<div className="navContainer marginTop1em ">
						<Link to="/dev"><div className="navItem white navItemGrid navItemLink"><div className="krakoan navItemIcon">k</div><h2 className="navItemText listFirst">Dev</h2></div></Link>
						<Link to="/photo"><div className="navItem white navItemGrid navItemLink"><div className="krakoan navItemIcon">y</div><h2 className="navItemText listSecond" >Photo</h2></div></Link>
						<Link to="/draw"><div className="navItem white navItemGrid navItemLink"><div className="krakoan navItemIcon">l</div><h2 className="navItemText listThird" >Draw</h2></div></Link>
						<Link to="/blog"><div className="navItem white navItemGrid navItemLink"><div className="krakoan navItemIcon">e</div><h2 className="navItemText listFourth">Blog</h2></div></Link>
						<a href="https://www.instagram.com/kylev/"><div className="navItem white navItemGrid navItemLink clickIt"><div className="socialFont navItemIcon ">i</div><h2 className="navItemText listFifth">IG</h2></div></a>
						<a href="https://codepen.io/kylevalenzuela"><div className="navItem white navItemGrid navItemLink clickIt"><div className="socialFont navItemIcon ">c</div><h2 className="navItemText listSixth">Pens</h2></div></a>
						<a href="https://www.linkedin.com/in/valenzuelaphoto/"><div className="navItem white navItemGrid navItemLink clickIt"><div className="socialFont navItemIcon ">l</div><h2 className="navItemText listSeventh">Spam</h2></div></a>
					</div>
				</div>
			</div>
		);
	}
}

export default Header;
