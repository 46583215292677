import React from 'react'
import {connect} from 'react-redux'
import {fetchPhotoRoll, fetchClickedDraw} from '../actions/api'
import Loading from '../components/Loading'
class PhotoRoll extends React.Component {
	componentDidMount() {
		this.props.fetchPhotoRoll();
	}
	toggleClick = (e, index) => {
		this.props.fetchClickedDraw(index);
	};
	render() {
		if (!this.props.photoRoll) {
			return <Loading />;
		}
		return this.props.photoRoll.map((item, index) => {
			return(
				<div className="clickIt" onClick={(e) => this.toggleClick(e, index)}><img src={item.acf.roll_image.url} alt="" /></div>
			);
		});
	}
}

const mapStateToProps = (state) => {
	return {
		photoRoll: state.api.photoRoll,
	};
};
export default connect(mapStateToProps, {fetchPhotoRoll, fetchClickedDraw})(PhotoRoll);