import React from 'react'
import {connect} from 'react-redux'
import {fetchDrawRoll, fetchClickedDraw, modalClose} from '../actions/api'
class DrawRoll extends React.Component {
	componentDidMount() {
		this.props.fetchDrawRoll();
	}
	componentWillUnmount() {
	}
	toggleClick = (e, index) => {
		this.props.fetchClickedDraw(index);

	};
	
	render() {
		if (!this.props.artRoll) {
			return <div>loading</div>;
		}
		return this.props.artRoll.map((item, index, arr) => {
			return(
				<div onClick={(e) => this.toggleClick(e, index)} className="clickIt devRollItem">
					<img src={item.acf.artRollImg} alt="" />
				</div>
			);
		});
	}
}

const mapStateToProps = (state) => {
	return {
		artRoll: state.api.artRoll,
	};
};
export default connect(mapStateToProps, {fetchDrawRoll,fetchClickedDraw, modalClose})(DrawRoll);