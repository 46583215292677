import React from 'react'
import {connect} from 'react-redux'
import {fetchDevPost} from '../actions/api'
import Footer from './Footer'
import {Link} from 'react-router-dom'
import Loading from '../components/Loading'
import ContactForm from '../components/ContactForm'
class DevMain extends React.Component {
	renderMeta = () => {
		setTimeout(() => {
			document.title= "Kyle Valenzuela | " + this.props.devPost.title.rendered;
     
	        let desc = document.createElement('meta');
	        desc.name = "description";
	        desc.content = + this.props.devPost.excerpt.rendered;
	        document.getElementsByTagName('head')[0].appendChild(desc);
		}, 500);
	};
	componentDidMount = () => {
		const slug = this.props.match.params.slug;
		this.props.fetchDevPost(slug);
		setTimeout(this.renderMeta, 1000);
		
	};
	componentWillUnmount = () => {
		document.title= "";
		let desc = document.querySelector('meta[name="description');
		try{
			desc.outerHTML="";
		}
		catch(e){ 
			console.log(e);
		}
	};

	render() {
		if (!this.props.devPost) {
			return <Loading/>;
		}
		const dev = this.props.devPost;
		return(
			<div>
				<div className="maxWidthWrap marginTop5em">
					<div className="contentPadding blogMainContent">
						<Link to="/dev" ><div className="marginBottom1em"> /dev/</div></Link>
						<h1 className="marginBottom1em">{dev.title.rendered}</h1>
						<div dangerouslySetInnerHTML={{ __html: dev.content.rendered }}></div>
					</div>
				</div>
					<div className="gridCenter fullWidth cfWrap"><ContactForm /></div>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	console.log(state);
	return {
		devPost: state.api.devPost[0],
	};
};
export default connect(mapStateToProps, {fetchDevPost})(DevMain);