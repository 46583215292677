import React from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import Loading from '../components/Loading'
class BlogRoll extends React.Component {
	render(){
		console.log(this.props.blogRoll);
		if (!this.props.blogRoll) {
			return <Loading />;
		}
		return this.props.blogRoll.map(item => {
			return(
				<Link className="blogRollLink" to={"/blog/" + item.slug} title={item.title.rendered}>
					<div className="contentPaddingY">
						<h3 className="marginBottomHalf">{item.title.rendered}</h3>
						<time className="marginBottomHalf">{new Date(item.date).toUTCString().slice(0, -13)}</time>
						<div dangerouslySetInnerHTML={{ __html: item.excerpt.rendered }}></div>
					</div>
				</Link>
			);
		})
	}
}

const mapStateToProps = (state) => {
	return {
		blogRoll: state.api.blogRoll,
	};
};

export default connect(mapStateToProps, {})(BlogRoll);