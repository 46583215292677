import {
	FETCH_BLOG_ROLL,
	FETCH_DEV_ROLL,
	FETCH_BLOG_POST,
	CLEAR_DEV_ROLL,
	FETCH_PHOTO_ROLL,
	FETCH_ART_ROLL,
	FETCH_DEV_POST,
	FETCH_CLICKED_DRAW,
	OVERLAY_IS_CLICKED,
	FETCH_CONTACT_FORM

} from '../actions/types'
import _ from 'lodash'

const initialState = {
	blogRoll: "",
	devRoll: "",
	blogPost: "",
	devPost: "",
};


export default (state = initialState, action) => {
	switch (action.type) {
		case FETCH_BLOG_ROLL:
			return {...state, blogRoll: JSON.parse(action.payload)};
		case FETCH_DEV_ROLL:
			return {...state, devRoll: JSON.parse(action.payload)};
		case FETCH_BLOG_POST:
			return {...state, blogPost: JSON.parse(action.payload)};
		case CLEAR_DEV_ROLL:
			return {...state, devRoll: null};
		case FETCH_PHOTO_ROLL:
			return {...state, photoRoll: JSON.parse(action.payload)};
		case FETCH_ART_ROLL:
			return {...state, artRoll: JSON.parse(action.payload)};
		case FETCH_DEV_POST:
			return {...state, devPost: JSON.parse(action.payload)};
		case FETCH_CLICKED_DRAW:
			return {...state, devClicked: action.payload};
		case OVERLAY_IS_CLICKED:
			return {...state, overlayIsClicked: action.payload};
		case FETCH_CONTACT_FORM:
			return {...state, contactForm: JSON.parse(action.payload)};
		default: 
			return state;
	}
};
