import React from 'react'
import loader from '../svg/loader.svg'

const Loading = () => {
	return(
		<div className="loadingWrap gridCenter marginBottom2em">
			<img className="positionRelative " src={loader} alt="" />
		</div>
	);
}
export default Loading;