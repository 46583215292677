import React from 'react'
import {connect} from 'react-redux'
import {fetchBlogPost} from '../actions/api'
import Footer from './Footer'
import {Link} from 'react-router-dom'
import ContactForm from '../components/ContactForm'
import Loading from '../components/Loading'

class BlogMain extends React.Component {
	renderMeta = () => {
		document.title= "Kyle Valenzuela | " + this.props.blogPost.title.rendered;
     
        let desc = document.createElement('meta');
        desc.name = "description";
        desc.content = + this.props.blogPost.excerpt.rendered;
        document.getElementsByTagName('head')[0].appendChild(desc);
	};
	componentDidMount = () => {
		const slug = this.props.match.params.slug;
		this.props.fetchBlogPost(slug);
		setTimeout(this.renderMeta, 1000);
		
	};
	componentWillUnmount = () => {
		document.title= "";
		let desc = document.querySelector('meta[name="description');
		desc.outerHTML="";
	};

	render() {
		if (!this.props.blogPost) {
			return <div>loading</div>;
		}
		const blog = this.props.blogPost;
		return(
		<div>
				<div className="maxWidthWrap marginTop5em overflow">
					<div className="contentPadding blogMainContent">
						<Link to="/blog" ><div className="marginBottom1em"> /blog/</div></Link>
						<h1 className="marginBottom1em">{blog.title.rendered}</h1>
						<div dangerouslySetInnerHTML={{ __html: blog.content.rendered }}></div>
					</div>
				</div>
					<div className="gridCenter fullWidth cfWrap"><ContactForm /></div>
		</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		blogPost: state.api.blogPost[0],
	};
};
export default connect(mapStateToProps, {fetchBlogPost})(BlogMain);