import React from 'react';
import {Router, Route, Switch, Redirect} from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistor, store } from './services/store';

import './App.scss';
import history from './history';
import LandingPage from './containers/LandingPage';
import DevHome from './containers/DevHome'
import Header from './containers/Header'
import BlogHome from './containers/BlogHome'
import BlogMain from './containers/BlogMain'
import PhotoHome from './containers/PhotoHome'
import DrawHome from './containers/DrawHome'
import DevMain from './containers/DevMain'

function App() {
  return (
    <div>
     <Provider store={store}>
            <Router history={history}>
              <Header />
              <div id="bodyHover" className="headerOffset ">
                <Switch>
                  <Route exact path="/" component={LandingPage} />
                  <Route exact path="/dev" component={DevHome} />
                  <Route path="/dev/:slug" component={DevMain}/>
                  <Route exact path="/photo" component={PhotoHome} />
                  <Route exact path="/blog" component={BlogHome} />
                  <Route exact path="/draw" component={DrawHome} />
                  <Route path="/blog/:slug" component={BlogMain}/>
                  <Redirect to="/" />
                </Switch>
              </div>
            </Router>
      </Provider>
    </div>
  );
}

export default App;
