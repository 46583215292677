import React from 'react'
import {Link} from 'react-router-dom'
import Particles from 'react-particles-js'

class LandingPage extends React.Component {
	render() {
		return(
			<div  className="landingContainer gridCenter purple" style={{borderLeft: '3px solid white'}}>
				<div className="tothetop">
					<h1 className="kneWave marginBottom1em white kneShadow ">Kyle Valenzuela</h1>
					<h6 className="textCenter white marginBottom2em fullWidth landingSub" >Something Inspirational to Show I Have Value</h6>
					<div className="gridOneTwo maxWidthWrap">
						<Link to="/dev"><h4 className="white textCenter photoFilterItem ppLink ">Development</h4></Link>
						<Link to="/photo"><h4 className="white textCenter photoFilterItem ppLink">Photography</h4></Link>
						<Link to="/draw"><h4 className="white textCenter photoFilterItem ppLink">Illustration</h4></Link>
						<Link to="/blog"><h4 className="white textCenter photoFilterItem ppLink">Blog</h4></Link>
					</div>
				</div>
				<Particles 
					className="particlesHome"
					params={{
					    "particles": {
					        "number": {
					            "value": 30
					        },
					        "size": {
					            "value": 3,
					            "anim": {
							        "enable": false,
							        "speed": 80,
							        "size_min": 0.1,
							        "sync": false
							      }
					        },
					        "move": {
						      "enable": true,
						      "speed": 1,
						      "direction": "none",
						      "random": false,
						      "straight": false,
						      "out_mode": "out",
						      "bounce": false,
						  	},
						  	"color": {
						      "value": "hsl(289, 27%, 75%)"
						    },
						    "opacity": {
						      "value": 0.1,
						      "random": false,
						      "anim": {
						        "enable": false,
						        "speed": 1,
						        "opacity_min": 0.1,
						        "sync": false
						      }
						    },
					    },
					    "interactivity": {
					        "events": {
					            "onhover": {
					                "enable": false,
					                "mode": "repulse"
					            }
					        }
					    }
					}}
				/>
			</div>
		);
	}

}
export default LandingPage;