import React from 'react'
import Footer from './Footer'
import PhotoRoll from '../components/PhotoRoll';
import MiniBlogRoll from '../components/MiniBlogRoll'
import DrawRoll from '../components/DrawRoll'
import model from '../models/kylevalenzuela.glb'
import '@google/model-viewer'
import {connect} from 'react-redux'
import {fetchDrawCategory, modalClose} from '../actions/api'
import close from '../svg/close.svg'
import space from '../img/galaxy.jpg'
import Loading from '../components/Loading'
import Down from '../svg/Down.js'
import ContactForm from '../components/ContactForm'

class DrawHome extends React.Component {
	constructor(props){
		super(props);
		this.artContainer = React.createRef();
	}
	handleOnClick = (event) => {
		//.current is verification that your element has rendered
		if(this.artContainer.current){
		    this.artContainer.current.scrollIntoView({ 
		       behavior: "smooth", 
		       block: "nearest"
		    })
		}
	}
	handleCategoryToggle = (catID) => {
		this.props.fetchDrawCategory(catID);
	};
	renderPopOut = () => {
		return ;
	};
	onClose = () => {
		this.props.modalClose();
	};
	render() {
		
		return(
			<div className="overflow">
				<div className="photoHero" >
					<model-viewer class="modelViewer" src={model} camera-controls min-camera-orbit="auto auto auto" max-camera-orbit="-54.23deg 84.62deg 10.13m" camera-orbit="-36.15deg 88.85deg 10.13m" environment-image={space} shadow-intensity="0" shadow-softness="0.37" exposure="2" poster={space} skybox-image={space}>
					    
					</model-viewer>
					<div className="drawHeroButtonWrap ">
						<div onClick={this.handleOnClick} className="drawHeroButton clickIt">
							<Down />
						</div>
					</div>
				</div>
				<div ref={this.artContainer} className="maxWidthWrap contentPadding">
						<div className=" gridGap10px photoFilterContainer">
							<h3 onClick={() => this.handleCategoryToggle('24+25+26')} className="clickIt photoFilterItem pLink">All</h3>
							<h3 onClick={() => this.handleCategoryToggle(24)} className="clickIt photoFilterItem pLink">Comic</h3>
							<h3 onClick={()=> this.handleCategoryToggle(25)} className="clickIt photoFilterItem pLink">Illustration</h3>
							<h3 onClick={() => this.handleCategoryToggle('26')} className="clickIt photoFilterItem pLink">Cartoon</h3>
						</div>
						<div className="photoGrid">
							<DrawRoll />
							{this.props.overlayIsClicked ? <div className="popOutModal "><div onClick={this.onClose} className="modalClose clickIt"><img src={close} alt="" /></div><img src={this.props.artRoll[this.props.devClicked].acf.popOutImg} alt="" /></div> : '' }
						</div>
						<div className="miniBlogContainer">
							<h1 className="gridCenter marginBottom1em">Blog</h1>
							<div className="gridOneTwoFour positionRelative"><MiniBlogRoll /></div>
						</div>
				</div>
				<div className="gridCenter fullWidth cfWrap"><ContactForm /></div>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		artRoll: state.api.artRoll,
		devClicked: state.api.devClicked,
		overlayIsClicked: state.api.overlayIsClicked,
	};
};
export default connect(mapStateToProps, {fetchDrawCategory, modalClose})(DrawHome);