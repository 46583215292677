import React from 'react'
const Down = () => {
	return(
		<svg width="100px" height="100px" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
		    <defs>
		        <polygon id="path-1" points="50 0 97.5528258 36.2687912 79.3892626 94.9529282 20.6107374 94.9529282 2.44717419 36.2687912"></polygon>
		        <mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="-2" y="-2" width="104" height="108.977376">
		            <rect x="-2" y="-2" width="104" height="108.977376" fill="white"></rect>
		            <use xlinkHref="#path-1" fill="black"></use>
		        </mask>
		        <polygon id="path-3" points="21.30375 24.69625 34.5 37.86375 47.69625 24.69625 51.75 28.75 34.5 46 17.25 28.75"></polygon>
		        <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="filter-4">
		            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
		            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
		            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
		        </filter>
		    </defs>
		    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		        <g id="Group-2" transform="translate(0.000000, 3.000000)">
		            <g id="Polygon-1">
		                <use fill="#3D2786" fillRule="evenodd" xlinkHref="#path-1"></use>
		                <use stroke="#FFFFFF" mask="url(#mask-2)" strokeWidth="4" xlinkHref="#path-1"></use>
		            </g>
		            <g className="downArrow" id="keyboard_arrow_down_black_24dp" transform="translate(16.000000, 18.000000)">
		                <g id="Group">
		                    <polygon id="Shape" points="0 0 69 0 69 69 0 69"></polygon>
		                    <g id="Shape">
		                        <use fill="black" fillOpacity="1" filter="url(#filter-4)" xlinkHref="#path-3"></use>
		                        <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-3"></use>
		                    </g>
		                </g>
		            </g>
		        </g>
		    </g>
		</svg>
	);
};
export default Down;