import React from 'react'
import Footer from './Footer'
import BlogRoll from '../components/BlogRoll'
import {fetchBlogRoll} from '../actions/api'
import ContactForm from '../components/ContactForm'
import {connect} from 'react-redux'
class BlogHome extends React.Component {
	componentDidMount() {
		this.props.fetchBlogRoll();
	}
	render(){

		return(
			<div>
				<div className="maxWidthWrap900 contentPadding marginTop5em overflow">
					<h1>The Archive</h1>
					<div className="contentPadding positionRelative">
						<BlogRoll />
					</div>
				</div>
					<div className="gridCenter fullWidth cfWrap"><ContactForm /></div>
			</div>
		);
	}
}
export default connect(null, {fetchBlogRoll})(BlogHome);