import React from 'react'
import Footer from './Footer'
import PhotoRoll from '../components/PhotoRoll';
import MiniBlogRoll from '../components/MiniBlogRoll'
import {connect} from 'react-redux'
import {fetchPhotoCategory, modalClose} from '../actions/api'
import close from '../svg/close.svg'
import Carousel from '../components/Carousel'
import Loading from '../components/Loading'
import ContactForm from '../components/ContactForm'
import Down from '../svg/Down.js'
class PhotoHome extends React.Component {
	constructor(props){
		super(props);
		this.photoContainer = React.createRef();
		this.isLoaded = '';
	}
	
	
	handleCategoryToggle = (catID) => {
		this.props.fetchPhotoCategory(catID);
	};
	handleOnClick = (event) => {
		//.current is verification that your element has rendered
		if(this.photoContainer.current){
		    this.photoContainer.current.scrollIntoView({ 
		       behavior: "smooth", 
		       block: "nearest"
		    })
		}
	}
	onClose = () => {
		this.props.modalClose();
	};

		
	modalOverlay() {
		if(this.props.photoRoll && this.props.devClicked) {
			const {photoRoll, devClicked} = this.props;
			const vCat = photoRoll[devClicked].photo_genre;
			const {video} = photoRoll[devClicked].acf;
			const {photo_gallery} = photoRoll[devClicked].acf;
			if (!photoRoll[devClicked]) {
				return <Loading />;
			}
			if (vCat.includes(5)) {
				
				return(
					<div className="popOutModal">
						<div onClick={this.onClose} className="modalClose clickIt">
							<img onClick={this.onClose}  src={close} alt="" />
						</div>
						<div dangerouslySetInnerHTML={{ __html: video}}></div>
					</div>
				); 
			}
			else {
				return(
					<div className="popOutModal ">
						<div onClick={this.onClose} className="modalClose clickIt">
							<img className="closeImg" src={close} alt="" />
						</div>
						<Carousel roll={photo_gallery} /> 
					</div> 
				);
			}
		}

	};
	
	render() {
		const {overlayIsClicked} = this.props;
		return(
			<div className="overflow">
				<div className="photoHero gridCenter">
					<div className="photoHeroText ">
						<h1 className="kneWave textCenter marginBottom1em">Kyle Valenzuela</h1>
						<h6 className="textCenter white">Photo and Video Production</h6>
					</div>
					<div className="drawHeroButtonWrap ">
						<div onClick={this.handleOnClick} className="drawHeroButton clickIt">
							<Down/>
						</div>
					</div>
					 <video className="heroVideo" muted autoPlay loop>
					  	<source src="https://res.cloudinary.com/kylevalenzuela/video/upload/v1624330073/kyleHeroBanner_zp3grk.mp4" type="video/mp4"/>
							Your browser does not support the video tag.
					</video> 
				</div>
				<div ref= {this.photoContainer}className="maxWidthWrap contentPadding photoContentWrap">
						<div className=" gridGap10px photoFilterContainer marginTop">
							<h3 onClick={()=> this.handleCategoryToggle('2+3+4+5')} className="clickIt photoFilterItem">All</h3>
							<h3 onClick={()=> this.handleCategoryToggle(2)} className="clickIt photoFilterItem pLink">Product</h3>
							<h3 onClick={()=> this.handleCategoryToggle(3)} className="clickIt photoFilterItem pLink">Landscape</h3>
							<h3 onClick={()=> this.handleCategoryToggle(4)} className="clickIt photoFilterItem pLink">Portrait</h3>
							<h3 onClick={()=> this.handleCategoryToggle(5)} className="clickIt photoFilterItem pLink">Video</h3>
						</div>
						<div className="photoGrid positionRelative">
							<PhotoRoll />
							{overlayIsClicked ? this.modalOverlay() : '' }
						</div>
						<div className="miniBlogContainer">
							<h1 className="gridCenter marginBottom1em marginTop">Blog</h1>
							<div className="gridOneTwoFour positionRelative"><MiniBlogRoll /></div>
						</div>
				</div>
					<div className="gridCenter fullWidth cfWrap"><ContactForm /></div>

			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		photoRoll: state.api.photoRoll,
		devClicked: state.api.devClicked,
		overlayIsClicked: state.api.overlayIsClicked,
	};
};
export default connect(mapStateToProps, {fetchPhotoCategory, modalClose})(PhotoHome);